@import '../../styles/colors';
@import '../../styles/mixins/_flexbox';
@import '../../styles/mixins/_utility';

.form-input {
    @include size(100%, 3rem);
    position: relative;
    margin: 1.25rem 0;

    &-holder {
        @include size(100%, 100%);
        @include border-radius(0.5rem, 0.5rem, 0.5rem, 0.5rem);
        will-change: transform, box-shadow;
        transition: 0.2s ease-in-out;
    }

    &-input {
        @include size();
        @include border-radius(0.5rem, 0.5rem, 0.5rem, 0.5rem);
        font-size: 1.125rem;
        padding: 0 5%;
        border: none;

        &:focus {
            outline: None;
        }
    }

    &-label {
        @include size(calc(100% - 10%), 100%);
        @include flex(center, start);
        position: absolute;
        font-size: 1em;
        left: 5%;
        top: 0;
        color: $taupe-gray;
        font-weight: 400;
        text-transform: capitalize;
        pointer-events: none;
        transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
        will-change: transform, font-size;
    }

    &--focus & {
        &-holder {
            box-shadow: 0px 5px 20px 0px rgba($charcoal, 0.3);
        }

        &-label {
            color: $white;
            font-size: 0.85rem;
            transform: translateY(-2.5rem);
        }
    }

    &--open & {
        &-label {
            color: $white;
            font-size: 0.85rem;
            transform: translateY(-2.5rem);
        }
    }
}
