@import '../../styles/colors';
@import '../../styles/mixins/utility';
@import '../../styles/mixins/flexbox';

/* for header */
.header-img{
    min-height: 35rem;
    background-image: url('../../images/class/gallery-image-8_2.png');
    background-size: cover;
    @media (max-width: 768px) {
        background-image: url('../../images/class/112.png');
    }
    @media (max-width: 375px) {
        background-image: url('../../images/class/113.png');
    }
}