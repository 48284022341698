@import '../../styles/colors';
@import '../../styles/mixins/utility';
@import '../../styles/mixins/flexbox';

$container-width: 340px; //305px;

/* for header */
.header-img-classList{
    min-height: 35rem;
    background-image: url('../../images/class/gallery-image-8_2.png');
    background-size: cover;
    @media (max-width: 768px) {
        background-image: url('../../images/class/112.png');
    }
    @media (max-width: 375px) {
        background-image: url('../../images/class/113.png');
    }
}

.particles-top-classList{
    @include size(100%,35rem);
    margin: 0 0 1rem 0;
    position: absolute;
    top:0;

}

.classList-header{
    position: relative;
    width: 100vw;
    .title-classList{
        width: 100vw;
        @include flex(center,center,row);  
        margin: 10rem auto;
        position: absolute;
        top:0;
        color:$white;
        @media (min-width: 768px) {
            font-size: 35px;
        }
    }

    .title-desciption-classList{
        width: 100vw;
        @include flex(center,center,row); 
        margin: 13rem auto 1rem auto;
        position: absolute;
        top:0;
        color: $primary;
        font-weight:900;
        font-size: 12px;
        @media (min-width: 768px) {
            font-size: 22px;
        }
    }
    .button-center-container{
        position: relative;
        height: 40px;
        height: 10rem;
    .button-top-classList{
        width: 150px;
        @include flex(center,center,row); 
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top:18rem;
        left: 0;
        right: 0;
        color:$primary !important;
        box-shadow: 0 0 0 2px $primary inset!important;
        background-color:transparent !important;
    }

    .button-top-classList:hover{
        background-color: $primary !important;
        color: $secondary !important;
    }
}
}

.more-bottom-space{
    margin-bottom: 0px;
    padding: 3rem 3rem 45px 3rem;

    .image-container-center{
        align-items:center;
        margin-bottom: 3rem;
        
        .image-container-center-auto{
            margin: 0 auto;
        }
    }


    .user-info-container{
        @include size($container-width, auto);
        @include flex(flex-start, flex-start, column);
        margin: 0 auto;
        overflow: hidden;
        background-color:$primary;

        @media (max-width: 766px) {
           margin-bottom: -30px;
        }

        @media (max-width: 536px) {
            @include size(260px, auto);
        }

        > h3 {
            margin: 0;
            padding: 1rem 0 2rem;
            // text-align: center;
            color: $white;
        }

        > h4 {
            font-size: 0.875rem;
            margin: 0.75rem 0;
            text-align: center;
            color: $white;
        }

        > .drop-down{
            margin-bottom: 1.25rem;
            width: 100%!important;
        }

        > .button--large--cta {
            @include border-radius(0.5rem, 0.5rem, 0.5rem, 0.5rem);
            background-color: $secondary;
            margin: 1.25rem auto;
        }

        > .shorten-width{
            width: 100% !important;

            >.button-center-container1{
                position: relative;
                height: 10rem;
                margin-bottom: -50px;
                >.button-center1{
                    @include flex(center,center,row);  
                    color:$white !important;
                    box-shadow: 0 0 0 2px $white inset!important;
                    background-color:transparent !important;
                    position: absolute;
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                    top: 10%;
                    width: 20%;
                    
                    @media (max-width:768px) {
                        width: 40%;                   
                    }
                }
            
                >.button-center1:hover{
                    background-color: $white !important;
                    color: $primary!important;
                }
            }
        }


    }
}

.section-class {
    background-color: $white;
    margin: 0;
    padding: 0.5rem 3rem 0rem 3rem;

    > ul {
        @include flex(flex-start, center, row);
        margin: 0rem 0 2rem 0;
        padding: 0;
        flex-wrap: wrap;
        list-style-type: none;

        > .block--width-free {
            @include size(98%, 25rem);
            padding: 1rem 0 10rem 0;    
            margin-bottom: 3rem;      
        }
    }

    .class-period {
        margin: 0;
        padding: 1rem 0 1rem 0.5rem;
        text-align: left;
        color: $primary-dark;
        font-size: 35px;
    }

    .card-icon {
        text-align: left;
        position: relative;
        > .img-top {
            @include size(100%, 25rem);
            border-radius: 0.5rem;
            box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
            // mask-image: linear-gradient(to top, transparent 25%, black 75%);
            // background-image: -moz-linear-gradient(270deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 95% );
            // background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 95% );
            // mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 95% );
        }

        >h3 {
            position: absolute;
            top: 9rem;
            left: 1rem;
            color: $white;
            font-size: 80px;
        }
    
        >p {
            position: absolute;
            top: 17.5rem;
            left: 1rem;
            color: $white;
            margin: 0.1rem 0 0.1rem 0;
            font-size: 18px;
        }

        >.class-comment{
            position: absolute;
            top: 19.5rem;
            left: 1rem;
            color: $white;
            margin: 0.1rem 0 0.1rem 0;
            font-size: 18px;
        }

    }
}
