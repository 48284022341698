@mixin size($width: inherit, $height: inherit) {
    width: $width;
    height: $height;
}

@mixin border-radius(
    $topLeft: 50%,
    $topRight: 50%,
    $bottomLeft: 50%,
    $bottomRight: 50%
) {
    border-radius: $topLeft $topRight $bottomRight $bottomLeft;
}