body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-content {
  background-color: #fff;
  width: 100vw; }

.container_main {
  width: 100vw; }
  @media (max-width: 768px) {
    .container_main {
      margin-top: 4.4rem;
      width: 100vw; } }

.header-dark {
  background-color: #262E2D !important;
  opacity: 0.9; }

.active-tab {
  border-color: #50E3C2 !important;
  color: #fff !important; }

.active-tab--none-active {
  color: #50E3C2 !important; }

.item-mobile:before {
  background: transparent !important; }

.sidebar-mobile {
  min-width: 12em !important;
  min-height: 4em !important;
  text-align: left !important;
  justify-content: center !important;
  padding-top: 1.5em !important;
  color: #50E3C2 !important; }

.sidebar-mobile:hover {
  background-color: #50E3C2 !important;
  color: #fff !important; }

.footer {
  width: 100vw;
  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  background-color: #262E2D;
  color: #50E3C2;
  font-weight: bold;
  font-size: 16px;
  /* for mobile */ }
  .footer .label-name {
    position: absolute;
    left: 6.25%; }
  .footer .icon-triangle--right {
    width: 1rem;
    height: 1rem;
    margin-left: 1rem;
    margin-right: 1rem; }
  .footer .icon-fb {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 12.25%; }
  .footer .icon-ig {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 6.25%; }
  .footer .icon-triangle--right-mobile {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem; }
  .footer .icon-fb-mobile {
    width: 2rem;
    height: 2rem;
    margin-right: 2rem; }
  .footer .icon-ig-mobile {
    width: 2rem;
    height: 2rem; }

/* for header */
.header-img {
  min-height: 35rem;
  background-image: url(/static/media/gallery-image-8_2.27cf3755.png);
  background-size: cover; }
  @media (max-width: 768px) {
    .header-img {
      background-image: url(/static/media/112.840ab6be.png); } }
  @media (max-width: 375px) {
    .header-img {
      background-image: url(/static/media/113.3fb47a59.png); } }

.particles-top {
  width: 100%;
  height: 35rem;
  margin: 0 0 1rem 0;
  position: absolute;
  top: 0; }

.title {
  margin: 10rem 0 1rem 3rem;
  position: absolute;
  top: 0;
  color: #fff; }
  @media (min-width: 768px) {
    .title {
      font-size: 35px; } }

.title-desciption {
  margin: 13rem 0 1rem 3rem;
  position: absolute;
  top: 0;
  color: #50E3C2;
  font-weight: 900; }
  @media (min-width: 768px) {
    .title-desciption {
      font-size: 22px; } }

.button-top {
  position: absolute;
  top: 18rem;
  left: 3rem;
  color: #50E3C2 !important;
  box-shadow: 0 0 0 2px #50E3C2 inset !important;
  background-color: transparent !important; }

.button-top:hover {
  background-color: #50E3C2 !important;
  color: #262E2D !important; }

/* for landing page body */
/* introduction */
.title-introduction {
  align-items: center;
  justify-content: center;
  width: 100vw;
  top: 35rem;
  color: #1CB5AC;
  margin-bottom: 1rem; }
  .title-introduction h2 {
    margin-top: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: center; }
    @media (min-width: 768px) {
      .title-introduction h2 {
        font-size: 28px; } }
  .title-introduction > .subtitle-introduction {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: #262E2D; }
    @media (min-width: 768px) {
      .title-introduction > .subtitle-introduction {
        font-size: 22px; } }
  .title-introduction .subtitle-introduction-margin-top {
    margin-top: 10% !important; }
    @media (max-width: 768px) {
      .title-introduction .subtitle-introduction-margin-top {
        margin-top: 20% !important; } }
    @media (min-width: 768px) {
      .title-introduction .subtitle-introduction-margin-top {
        font-size: 28px; } }
  .title-introduction .triangle-double {
    width: 100vw; }
  .title-introduction .button-center-container {
    position: relative;
    height: 40px;
    height: 10rem; }
    .title-introduction .button-center-container .button-center {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      color: #50E3C2 !important;
      box-shadow: 0 0 0 2px #50E3C2 inset !important;
      background-color: transparent !important;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 10%;
      width: 20%; }
      @media (max-width: 768px) {
        .title-introduction .button-center-container .button-center {
          width: 40%; } }
    .title-introduction .button-center-container .button-center:hover {
      background-color: #50E3C2 !important;
      color: #fff !important; }

.contex-introduction {
  width: 100vw;
  top: 45rem;
  background-color: #fff;
  margin: 0;
  padding: 0.5rem 3rem 0 3rem; }
  .contex-introduction > ul {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    margin: -1rem 0 2rem 0;
    padding: 0;
    flex-wrap: wrap;
    list-style-type: none; }
    .contex-introduction > ul > .li-intro {
      width: 48%;
      height: 10rem;
      margin: 0.5rem 0.8rem 0.5rem 0;
      padding: 1rem 0rem 10rem 0; }
      @media (max-width: 768px) {
        .contex-introduction > ul > .li-intro {
          height: auto;
          height: initial;
          width: auto;
          width: initial;
          padding: 0; } }
      @media (min-width: 768px) {
        .contex-introduction > ul > .li-intro .contex-introduction-title {
          font-size: 18px !important; } }
      .contex-introduction > ul > .li-intro p {
        color: #262E2D;
        margin-left: 1rem; }
        @media (min-width: 768px) {
          .contex-introduction > ul > .li-intro p {
            font-size: 20px; } }

.contex-question {
  width: 100vw;
  top: 45rem;
  background-color: #50E3C2;
  margin: -3px 0;
  padding: 0.5rem 3rem 1rem 3rem; }
  .contex-question > ul {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    margin: -1rem 0 2rem 0;
    padding: 0;
    flex-wrap: wrap;
    list-style-type: none; }
    .contex-question > ul > .li-intro {
      width: 48%;
      height: 10rem;
      margin: 0.5rem 0.8rem 3rem 0;
      padding: 1rem 0rem 10rem 0; }
      @media (max-width: 768px) {
        .contex-question > ul > .li-intro {
          height: auto;
          height: initial;
          width: auto;
          width: initial;
          padding: 0; } }
      .contex-question > ul > .li-intro p {
        margin-top: 20px;
        color: #262E2D; }
        @media (min-width: 768px) {
          .contex-question > ul > .li-intro p {
            font-size: 18px; } }
      .contex-question > ul > .li-intro .answer-dialog {
        width: 100%;
        min-height: 70px;
        background-color: #50E3C2;
        border: 2px solid white; }
        .contex-question > ul > .li-intro .answer-dialog p {
          width: 100%;
          text-align: left;
          white-space: normal; }
      .contex-question > ul > .li-intro .answer-dialog:before {
        border-width: 2px 0 0 2px;
        top: -2px;
        left: 50%; }
  .contex-question > h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    color: #fff; }

.title-with-background {
  position: relative; }
  .title-with-background h2 {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 1%;
    width: 55%; }
    @media (max-width: 536px) {
      .title-with-background h2 {
        font-size: 18px;
        top: -30%; } }
  .title-with-background .title-with-background-white-title {
    color: #fff; }
    @media (max-width: 536px) {
      .title-with-background .title-with-background-white-title {
        font-size: 18px;
        top: -100%; } }

.school-image {
  align-items: center;
  justify-content: center;
  width: 100vw;
  top: 35rem;
  background-color: #50E3C2;
  margin-bottom: -0.8px;
  padding: 3rem 3rem 10px 3rem; }
  .school-image > h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    color: #fff;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .school-image > h2 {
        font-size: 28px; } }
  .school-image .grid-teacher {
    margin-bottom: 20px !important; }
  .school-image .teacher-image {
    margin: 1rem auto;
    border: 4px solid white;
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.2);
    display: inline-block;
    border-radius: 50% !important;
    max-width: 170px !important; }
    @media (min-width: 768px) {
      .school-image .teacher-image {
        max-width: 200px !important; } }
  .school-image p {
    text-align: center;
    color: gray;
    margin: 0; }
    @media (min-width: 768px) {
      .school-image p {
        font-size: 18px; } }
  .school-image .p-comment {
    color: 'gray';
    margin-bottom: 12px; }

.feedback-introduction {
  align-items: center;
  justify-content: center;
  width: 100vw;
  top: 35rem;
  color: #1CB5AC;
  margin-bottom: 1rem; }
  .feedback-introduction .feedback-introduction-wrapper {
    background-image: url(/static/media/feedback.5b77546f.png);
    background-size: cover;
    height: 400px; }
    @media (max-width: 768px) {
      .feedback-introduction .feedback-introduction-wrapper {
        width: 100%;
        height: 360px; } }
  .feedback-introduction .feedback-introduction-dialog-container {
    position: relative;
    width: 768px;
    height: 400px;
    margin: 0 auto; }
    @media (max-width: 768px) {
      .feedback-introduction .feedback-introduction-dialog-container {
        width: 100%;
        height: 360px; } }
  .feedback-introduction .triangle-double {
    width: 100vw; }
  .feedback-introduction .feedback-dialog {
    background-color: #fff !important;
    border: 2px solid #50E3C2 !important;
    position: absolute !important;
    width: 380px;
    min-height: 120px; }
    @media (max-width: 768px) {
      .feedback-introduction .feedback-dialog {
        width: 210px;
        min-height: 100px; } }
    .feedback-introduction .feedback-dialog p {
      width: 100%;
      text-align: left;
      white-space: normal;
      color: #50E3C2; }
      @media (min-width: 768px) {
        .feedback-introduction .feedback-dialog p {
          font-size: 18px; } }
  .feedback-introduction .feedback-dialog-first {
    top: 4rem;
    left: 4rem; }
    @media (max-width: 768px) {
      .feedback-introduction .feedback-dialog-first {
        left: 2rem; } }
  .feedback-introduction .feedback-dialog-first:before {
    border-width: 2px 2px 0 0 !important;
    right: -1px !important; }
  .feedback-introduction .feedback-dialog-second {
    bottom: 3rem;
    right: 4rem; }
    @media (max-width: 768px) {
      .feedback-introduction .feedback-dialog-second {
        right: 2rem; } }
  .feedback-introduction .feedback-dialog-second:before {
    border-width: 0 0 2px 2px !important;
    left: -1px !important; }
  .feedback-introduction .feedback-person-first {
    position: absolute;
    top: 5rem;
    left: 35rem;
    width: 97px; }
    @media (max-width: 768px) {
      .feedback-introduction .feedback-person-first {
        top: 5rem;
        left: 19rem;
        width: 84px; } }
  .feedback-introduction .feedback-person-second {
    position: absolute;
    bottom: 4rem;
    right: 35rem;
    width: 97px; }
    @media (max-width: 768px) {
      .feedback-introduction .feedback-person-second {
        bottom: 4rem;
        right: 19rem;
        width: 84px; } }

/* for header */
.header-img-classList {
  min-height: 35rem;
  background-image: url(/static/media/gallery-image-8_2.27cf3755.png);
  background-size: cover; }
  @media (max-width: 768px) {
    .header-img-classList {
      background-image: url(/static/media/112.840ab6be.png); } }
  @media (max-width: 375px) {
    .header-img-classList {
      background-image: url(/static/media/113.3fb47a59.png); } }

.particles-top-classList {
  width: 100%;
  height: 35rem;
  margin: 0 0 1rem 0;
  position: absolute;
  top: 0; }

.classList-header {
  position: relative;
  width: 100vw; }
  .classList-header .title-classList {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 10rem auto;
    position: absolute;
    top: 0;
    color: #fff; }
    @media (min-width: 768px) {
      .classList-header .title-classList {
        font-size: 35px; } }
  .classList-header .title-desciption-classList {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 13rem auto 1rem auto;
    position: absolute;
    top: 0;
    color: #50E3C2;
    font-weight: 900;
    font-size: 12px; }
    @media (min-width: 768px) {
      .classList-header .title-desciption-classList {
        font-size: 22px; } }
  .classList-header .button-center-container {
    position: relative;
    height: 40px;
    height: 10rem; }
    .classList-header .button-center-container .button-top-classList {
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: 18rem;
      left: 0;
      right: 0;
      color: #50E3C2 !important;
      box-shadow: 0 0 0 2px #50E3C2 inset !important;
      background-color: transparent !important; }
    .classList-header .button-center-container .button-top-classList:hover {
      background-color: #50E3C2 !important;
      color: #262E2D !important; }

.more-bottom-space {
  margin-bottom: 0px;
  padding: 3rem 3rem 45px 3rem; }
  .more-bottom-space .image-container-center {
    align-items: center;
    margin-bottom: 3rem; }
    .more-bottom-space .image-container-center .image-container-center-auto {
      margin: 0 auto; }
  .more-bottom-space .user-info-container {
    width: 340px;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0 auto;
    overflow: hidden;
    background-color: #50E3C2; }
    @media (max-width: 766px) {
      .more-bottom-space .user-info-container {
        margin-bottom: -30px; } }
    @media (max-width: 536px) {
      .more-bottom-space .user-info-container {
        width: 260px;
        height: auto; } }
    .more-bottom-space .user-info-container > h3 {
      margin: 0;
      padding: 1rem 0 2rem;
      color: #fff; }
    .more-bottom-space .user-info-container > h4 {
      font-size: 0.875rem;
      margin: 0.75rem 0;
      text-align: center;
      color: #fff; }
    .more-bottom-space .user-info-container > .drop-down {
      margin-bottom: 1.25rem;
      width: 100% !important; }
    .more-bottom-space .user-info-container > .button--large--cta {
      border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
      background-color: #262E2D;
      margin: 1.25rem auto; }
    .more-bottom-space .user-info-container > .shorten-width {
      width: 100% !important; }
      .more-bottom-space .user-info-container > .shorten-width > .button-center-container1 {
        position: relative;
        height: 10rem;
        margin-bottom: -50px; }
        .more-bottom-space .user-info-container > .shorten-width > .button-center-container1 > .button-center1 {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          color: #fff !important;
          box-shadow: 0 0 0 2px #fff inset !important;
          background-color: transparent !important;
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          top: 10%;
          width: 20%; }
          @media (max-width: 768px) {
            .more-bottom-space .user-info-container > .shorten-width > .button-center-container1 > .button-center1 {
              width: 40%; } }
        .more-bottom-space .user-info-container > .shorten-width > .button-center-container1 > .button-center1:hover {
          background-color: #fff !important;
          color: #50E3C2 !important; }

.section-class {
  background-color: #fff;
  margin: 0;
  padding: 0.5rem 3rem 0rem 3rem; }
  .section-class > ul {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    margin: 0rem 0 2rem 0;
    padding: 0;
    flex-wrap: wrap;
    list-style-type: none; }
    .section-class > ul > .block--width-free {
      width: 98%;
      height: 25rem;
      padding: 1rem 0 10rem 0;
      margin-bottom: 3rem; }
  .section-class .class-period {
    margin: 0;
    padding: 1rem 0 1rem 0.5rem;
    text-align: left;
    color: #1CB5AC;
    font-size: 35px; }
  .section-class .card-icon {
    text-align: left;
    position: relative; }
    .section-class .card-icon > .img-top {
      width: 100%;
      height: 25rem;
      border-radius: 0.5rem;
      box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1); }
    .section-class .card-icon > h3 {
      position: absolute;
      top: 9rem;
      left: 1rem;
      color: #fff;
      font-size: 80px; }
    .section-class .card-icon > p {
      position: absolute;
      top: 17.5rem;
      left: 1rem;
      color: #fff;
      margin: 0.1rem 0 0.1rem 0;
      font-size: 18px; }
    .section-class .card-icon > .class-comment {
      position: absolute;
      top: 19.5rem;
      left: 1rem;
      color: #fff;
      margin: 0.1rem 0 0.1rem 0;
      font-size: 18px; }

.form-input {
  width: 100%;
  height: 3rem;
  position: relative;
  margin: 1.25rem 0; }
  .form-input-holder {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    will-change: transform, box-shadow;
    transition: 0.2s ease-in-out; }
  .form-input-input {
    width: inherit;
    height: inherit;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    font-size: 1.125rem;
    padding: 0 5%;
    border: none; }
    .form-input-input:focus {
      outline: None; }
  .form-input-label {
    width: calc(100% - 10%);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    position: absolute;
    font-size: 1em;
    left: 5%;
    top: 0;
    color: #8b8f92;
    font-weight: 400;
    text-transform: capitalize;
    pointer-events: none;
    transition: font-size 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    will-change: transform, font-size; }
  .form-input--focus .form-input-holder {
    box-shadow: 0px 5px 20px 0px rgba(49, 61, 71, 0.3); }
  .form-input--focus .form-input-label {
    color: #fff;
    font-size: 0.85rem;
    -webkit-transform: translateY(-2.5rem);
            transform: translateY(-2.5rem); }
  .form-input--open .form-input-label {
    color: #fff;
    font-size: 0.85rem;
    -webkit-transform: translateY(-2.5rem);
            transform: translateY(-2.5rem); }


/* for header */
.header-img {
  min-height: 35rem;
  background-image: url(/static/media/gallery-image-8_2.27cf3755.png);
  background-size: cover; }
  @media (max-width: 768px) {
    .header-img {
      background-image: url(/static/media/112.840ab6be.png); } }
  @media (max-width: 375px) {
    .header-img {
      background-image: url(/static/media/113.3fb47a59.png); } }


