@import '../../styles/colors';
@import '../../styles/mixins/utility';
@import '../../styles/mixins/flexbox';

/* for header */
.header-img{
    min-height: 35rem;
    background-image: url('../../images/class/gallery-image-8_2.png');
    background-size: cover;
    @media (max-width: 768px) {
        background-image: url('../../images/class/112.png');
    }
    @media (max-width: 375px) {
        background-image: url('../../images/class/113.png');
    }
}

.particles-top{
    @include size(100%,35rem);
    margin: 0 0 1rem 0;
    position: absolute;
    top:0;

}

.title{
    margin: 10rem 0 1rem 3rem;
    position: absolute;
    top:0;
    color:$white;
    @media (min-width: 768px) {
        font-size: 35px;
    }
}

.title-desciption{
    margin: 13rem 0 1rem 3rem;
    position: absolute;
    top:0;
    color: $primary;
    font-weight:900;
    @media (min-width: 768px) {
        font-size: 22px;
    }
}

.button-top{
    position: absolute;
    top:18rem;
    left: 3rem;
    color:$primary !important;
    box-shadow: 0 0 0 2px $primary inset!important;
    background-color:transparent !important;
}

.button-top:hover{
    background-color: $primary !important;
    color: $secondary !important;
}

/* for landing page body */
/* introduction */
.title-introduction{
    align-items:center;
    justify-content: center;
    width: 100vw;
    top:35rem;
    color: $primary-dark;
    margin-bottom: 1rem;

    h2{
        margin-top: 20px !important;
        @include flex(center,center,row);
        text-align: center;
        @media (min-width: 768px) {
            font-size: 28px;
        }
    }

    >.subtitle-introduction{
        @include flex(center,center,row);
        color: $secondary;
        @media (min-width: 768px) {
            font-size: 22px;
        }
    }

    .subtitle-introduction-margin-top{
        margin-top:10% !important;

        @media (max-width:768px) {
            margin-top:20% !important;
        }

        @media (min-width: 768px) {
            font-size: 28px;
        }

    }

    .triangle-double{
        width: 100vw;
    }

    .button-center-container{
        position: relative;
        height: 40px;
        height: 10rem;
    .button-center{
        @include flex(center,center,row);  
        color:$primary !important;
        box-shadow: 0 0 0 2px $primary inset!important;
        background-color:transparent !important;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 10%;
        width: 20%;
        
        @media (max-width:768px) {
            width: 40%;
            
        }
    }

    .button-center:hover{
        background-color: $primary !important;
        color: $white !important;
    }
}
}

.contex-introduction{
    width:100vw;
    top:45rem;
    background-color: $white;
    margin: 0;
    padding: 0.5rem 3rem 0 3rem;

    > ul {
        @include flex(flex-start, center, row);
        margin: -1rem 0 2rem 0;
        padding: 0;
        flex-wrap: wrap;
        list-style-type: none;
   
        >.li-intro {
            @include size(48%, 10rem);
            margin: 0.5rem 0.8rem 0.5rem 0;
            padding: 1rem 0rem 10rem 0;

            @media (max-width: 768px) {
                height: initial;
                width: initial;
                padding: 0;
            }

            .contex-introduction-title{
                @media (min-width: 768px) {
                font-size: 18px !important;
                }
            }

            p{
                color: $secondary;
                margin-left: 1rem;
                @media (min-width: 768px) {
                    font-size: 20px;
                }
            }
        }
    }
}

.contex-question{
    width:100vw;
    top:45rem;
    background-color: $primary;
    margin: -3px 0;
    padding: 0.5rem 3rem 1rem 3rem;

    > ul {
        @include flex(flex-start, center, row);
        margin: -1rem 0 2rem 0;
        padding: 0;
        flex-wrap: wrap;
        list-style-type: none;
   
        >.li-intro {
            @include size(48%, 10rem);
            margin: 0.5rem 0.8rem 3rem 0;
            padding: 1rem 0rem 10rem 0;

            @media (max-width: 768px) {
                height: initial;
                width: initial;
                padding: 0;
            }

            p{
                margin-top: 20px;
                color: $secondary;
                @media (min-width: 768px) {
                    font-size: 18px;
                }
            }

            .answer-dialog{
                width: 100%;
                min-height: 70px;
                background-color: $primary;
                border: 2px solid white;
            
                p {
                    width: 100%;
                    text-align: left;
                    white-space: normal;
                }
            }
            
            .answer-dialog:before {
                border-width: 2px 0 0 2px;
                top: -2px;
                left: 50%;
            }
        }
    }

    >h2{
        @include flex(center,center,row);
        text-align: center;
        color: $white;
    }
}

.title-with-background {
    position: relative;

    h2 {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 1%;
        width: 55%;

        @media (max-width: 536px) {
            font-size: 18px;
            top:-30%;
        }
    }
    
    .title-with-background-white-title{
        color: $white;
        @media (max-width: 536px) {
            font-size: 18px;
            top:-100%;
        }
    }

}

.school-image{
    align-items:center;
    justify-content: center;
    width: 100vw;
    top:35rem;
    background-color: $primary;
    margin-bottom: -0.8px;
    padding: 3rem 3rem 10px 3rem;

    >h2{
        @include flex(center,center,row);
        text-align: center;
        color: $white;
        margin-bottom: 10px;
        @media (min-width: 768px) {
            font-size: 28px;
        }
    }

    .grid-teacher{
        margin-bottom: 20px !important;
    }

    .teacher-image{
        margin: 1rem auto;
        border: 4px solid white;
        box-shadow:2px 3px 3px rgba(0,0,0,0.2);
	    display:inline-block;
        border-radius: 50% !important;
        max-width: 170px !important;
        @media (min-width: 768px) {
            max-width: 200px !important;
        }
    }

    p{
        text-align: center;
        // color:$secondary;
        color: gray;
        margin: 0;
        @media (min-width: 768px) {
            font-size: 18px;
        }
    }

    .p-comment{
        color: 'gray';
        margin-bottom: 12px;
    }
    
}


.feedback-introduction{
    align-items:center;
    justify-content: center;
    width: 100vw;
    top:35rem;
    color: $primary-dark;
    margin-bottom: 1rem;

    .feedback-introduction-wrapper {
        background-image: url('../../images/feedback/feedback.png');
        background-size: cover;
        height: 400px;

        @media (max-width: 768px) {
            width: 100%;
            height: 360px;
        }
    }

    .feedback-introduction-dialog-container {
        position: relative;
        width: 768px;
        height: 400px;
        margin: 0 auto;

        @media (max-width: 768px) {
            width: 100%;
            height: 360px;
        }
    }

    .triangle-double{
        width: 100vw;      
    }
    
    .feedback-dialog {
        background-color:$white !important;
        border: 2px solid $primary !important;
        position: absolute !important;
        width: 380px;
        min-height: 120px;

        @media (max-width: 768px) {
            width: 210px;
            min-height: 100px;
         }

        p {
            width: 100%;
            text-align: left;
            white-space: normal;
            color: $primary;
            @media (min-width: 768px) {
               font-size: 18px
            }
        }
    }

    .feedback-dialog-first{
        top: 4rem;
        left: 4rem;

        @media (max-width: 768px) {
            left: 2rem;
         }
    }

    .feedback-dialog-first:before {
        border-width: 2px 2px 0 0 !important;
        right: -1px !important;
    }

    .feedback-dialog-second{
        bottom: 3rem;
        right: 4rem;

        @media (max-width: 768px) {
            right: 2rem;
        }
    }

    .feedback-dialog-second:before {
        border-width: 0 0 2px 2px !important;
        left: -1px !important;
    }

    .feedback-person-first {
        position: absolute;
        top: 5rem;
        left: 35rem;
        width: 97px;

        @media (max-width: 768px) {
            top: 5rem;
            left: 19rem;
            width: 84px;
        }
    }

    .feedback-person-second {
        position: absolute;
        bottom: 4rem;
        right: 35rem;
        width: 97px;

        @media (max-width: 768px) {
            bottom: 4rem;
            right: 19rem;
            width: 84px;
        }
    }
}


