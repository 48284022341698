@import '../../styles/colors';
.container_main{
    width: 100vw;

    @media (max-width:768px) {
        margin-top: 4.4rem;
        width: 100vw;
        
    }
}

.header-dark{
    background-color: $secondary !important;
    opacity: 0.9;
}

.active-tab{
    border-color: $primary !important;
    color: $white !important;
}

.active-tab--none-active{
    color: $primary !important;
}

.item-mobile:before{
    background: transparent !important;
}

.sidebar-mobile{
    min-width: 12em !important;
    min-height: 4em !important;
    text-align: left !important;
    justify-content: center !important;
    padding-top: 1.5em !important;
    color: $primary !important;
}

.sidebar-mobile:hover{
    background-color:$primary !important;
    color: $white !important;
}