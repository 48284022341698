@import '../../styles/colors';
@import '../../styles/mixins/utility';
@import'../../styles/mixins/flexbox';

$icon-size:2rem;

.footer{
  @include size(100vw, 9rem);
  @include flex(center,center,row);
  text-align: center;
  background-color:$secondary;
  color: $primary;
  font-weight: bold;
  font-size: 16px;

  .label-name{
    position: absolute;
    left:6.25%;
  }
 
  .icon-triangle--right{
    @include size(1rem, 1rem);
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .icon-fb{
    @include size($icon-size, $icon-size);
    position: absolute;
    right: 12.25%;
  }

  .icon-ig{
    @include size($icon-size, $icon-size);
    position: absolute;
    right: 6.25%;
  }

  /* for mobile */
  .icon-triangle--right-mobile{
    @include size(1rem, 1rem);
    margin-right: 1rem;
  }
  
  .icon-fb-mobile{
    @include size($icon-size, $icon-size);
    margin-right: 2rem;
  }

  .icon-ig-mobile{
    @include size($icon-size, $icon-size);
  }

}

